<template>
    <div id="delivery" class="product-details__content">
        
        <v-img src="/images/sub/mall/delivery-info.jpg" max-width="1100" :aspect-ratio="1100 / 2534" class="w-100 mx-auto" />

        <!-- <div class="pb-20px">
            <v-row align="center" class="row--sm">
                <v-col cols="auto">
                    <h3 class="tit tit--xxs">배송/교환/반품 정보</h3>
                </v-col>
            </v-row>
        </div>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <div class="txt txt--xs">
                    <tit-wrap-x-small title="반품"></tit-wrap-x-small>
                    <div>
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">반품신청​</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>구매자 책임사유시 반품/배송비 결제​</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">발송하기</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>1~2일 이내 택배기사 방문​</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">반품접수완료​</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>반품제품 전달​</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">반품완료</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>확인 후 환불처리​</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <div class="txt txt--xs">
                    <tit-wrap-x-small title="교환"></tit-wrap-x-small>
                    <div>
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">교환신청</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>구매자 책임사유시 반품/배송비 결제​</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">발송하기</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>1~2일 이내 택배기사 방문​</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">교환접수</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>교환제품 전달​</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">제품재배송​</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>확인 후 교환​</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row no-gutters>
                            <v-col cols="12" md="auto">
                                <div class="txt--light w-md-80px">교환완료</div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div>완료</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <div class="txt txt--xs">
                    <tit-wrap-x-small title="주의사항"></tit-wrap-x-small>
                    <div>
                        <v-row class="row--xs">
                            <v-col cols="auto">-</v-col>
                            <v-col cols=""> 미성년자가 물품을 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 구매를 취소할 수 있습니다.​​ </v-col>
                        </v-row>
                    </div>
                    <div class="pt-4px pt-md-8px">
                        <v-row class="row--xs">
                            <v-col cols="auto">-</v-col>
                            <v-col cols=""> 안전검사 대상 공산품’을 구매하시는 경우 품질경영 및 공산품 안전관리법에 따른 안전검사 표시가 있는 제품인지 확인하시기 바랍니다.​ </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        TitWrapXSmall,
    },
};
</script>

<style>
</style>